// 高级旗舰版
const senior = {
    home: {
        url: "/addons/chatgpt/index/pc_config",
        method: "POST",
        desc: "旗舰版 首页数据"
    },
    block: {
        url: "/addons/chatgpt/block/index",
        method: "POST",
        desc: "旗舰版 banner"
    },
    recommend: {
        url: "/addons/chatgpt/recommend_works/index",
        method: "POST",
        desc: "社区资源 推荐列表"
    },
    collect: {
        url: "/addons/chatgpt/recommend_works/operate",
        method: "POST",
        desc: "社区资源 收藏喜欢"
    },
    create_agent: {
        url: "/addons/chatgpt/agent/create_agent",
        method: "POST",
        desc: "社区资源 新建智能体"
    },
    topic: {
        url: "/addons/chatgpt/agent/topic",
        method: "POST",
        desc: " 智能体分类列表"
    },
    agent_list: {
        url: "/addons/chatgpt/agent/prompts",
        method: "POST",
        desc: " 智能体列表"
    },
    user_index: {
        url: "/addons/chatgpt/user/index",
        method: "POST",
        desc: "个人中心"
    },
    followList: {
        url: "/addons/chatgpt/follow/followList",
        method: "POST",
        desc: "关注粉丝列表"
    },
    follow: {
        url: "/addons/chatgpt/follow/follow",
        method: "POST",
        desc: "关注取关"
    },
    voteList: {
        url: "/addons/chatgpt/recommend_works/voteList",
        method: "POST",
        desc: "收藏列表"
    },
    zntchatList: {
        url: "/addons/chatgpt/agent/used_prompt",
        method: "POST",
        desc: "智能体聊天列表"
    },
    znthistory: {
        url: "/addons/chatgpt/web/history_write",
        method: "POST",
        desc: "智能体聊天历史"
    },
    delzntlist: {
        url: "/addons/chatgpt/agent/del_agent_record",
        method: "POST",
        desc: "删除智能体聊天"
    },
    new_mode_list: {
        url: "/addons/chatgpt/web/get_mode_new",
        method: "POST",
        desc: "新模型接口"
    },
    saveAgentGroup: {
        url: "/addons/chatgpt/agent/saveAgentGroup",
        method: "POST",
        desc: "新建智能体列表会话"
    },
    agent_prompt_group: {
        url: "/addons/chatgpt/agent/agent_prompt_group",
        method: "POST",
        desc: "查询智能体会话列表"
    },
    agent_history: {
        url: "/addons/chatgpt/agent/agent_history",
        method: "POST",
        desc: "查询智能体会话聊天记录"
    },
    sendTextOnly: {
        url: "/addons/chatgpt/agent/sendTextOnly",
        method: "POST",
        desc: "每次问题后  三个相似问题"
    },
    user_count: {
        url: "/addons/chatgpt/user/count",
        method: "POST",
        desc: "关注粉丝获赞"
    },
    recommend_detail: {
        url: "/addons/chatgpt/recommend_works/detail",
        method: "POST",
        desc: "社区资源详情"
    },
    voteList: {
        url: "/addons/chatgpt/recommend_works/voteList",
        method: "POST",
        desc: "个人中心 喜欢列表"
    },
    is_public: {
        url: "/addons/chatgpt/recommend_works/is_public",
        method: "POST",
        desc: "个人作品 私密公开"
    },
    num_statistics: {
        url: "/addons/chatgpt/reseller/num_statistics",
        method: "POST",
        desc: "分销中心 顶部数据"
    },
    userNew: {
        url: "/addons/chatgpt/reseller/userNew", 
        method: "POST",
        desc: "分销中心 邀请列表"
    },
    logNew: {
        url: "/addons/chatgpt/reseller/logNew", 
        method: "POST",
        desc: "分销中心 分销列表"
    },
    updateInfo: {
        url: '/addons/chatgpt/user/profile',
        method: 'POST',
        desc: '修改用户信息'
    },
    delete: {
        url: '/addons/chatgpt/user/delete',
        method: 'GET',
        desc: '删除用户'
    },
    del_agent: {
        url: '/addons/chatgpt/agent/del_agent',
        method: 'POST',
        desc: '删除智能体'
    },

    del_works: {
        url: '/addons/chatgpt/recommend_works/del_works',
        method: 'POST',
        desc: '删除我的作品'
    }, 
    
    
    



}

export default senior
