import { http } from "@/api/api"

export default {
	namespaced: true,
	state: {
		config: "",
		theme: 'light',
		sign: window.location.search.replace(/\?/g, ""),
		opacity:'0.1',
		qiancolor:'rgba(0, 0, 0,'
	},
	getters: {
		config: state => state.config
	},
	mutations: {
		set_qiancolor(state, qiancolor){
			state.qiancolor = qiancolor;
		},
		setConfig(state, data) {
			state.config = data
		},
		set_theme(state, theme) {
			state.theme = theme;
		},
		set_opacity(state, opacity){
			state.opacity = opacity;
		}
	},
	actions: {
		setQian({ commit }, qiancolor){
			commit('set_qiancolor', qiancolor);
		},
		setOpacity({ commit }, opacity){
			commit('set_opacity', opacity);
		},
		setTheme({ commit }, theme) {
			commit('set_theme', theme);
		},
		// 获取网站配置信息
		async getWebsiteConfigInfo({ commit, dispatch, getters, state }) {
			const result = await new Promise((resolve, reject) => {
				http("common.config")
					.then(res => {
						if (res.code === 1) {
							document.title = res.data.system.name
							commit("setConfig", res.data.system)
							resolve(res)
						} else {
							reject(res)
						}
					})
					.catch(err => {
						reject(err)
					})
			})
			return result
		}
	}
}
