// 公共
const common = {
	index: {
		url: "/addons/chatgpt/web/index",
		method: "GET",
		desc: "首页数据"
	},
	statistic: {
		url: "/addons/chatgpt/web/index",
		method: "GET",
		desc: "统计数据"
	},
	init: {
		url: "/addons/chatgpt/index/init",
		method: "GET",
		desc: "初始化"
	},
	upload: {
		url: "/addons/chatgpt/index/upload",
		method: "POST",
		desc: "上传图片"
	},
	config: {
		url: "/addons/chatgpt/index/about_us",
		method: "GET",
		desc: "网站配置"
	},
	appconfig: {
		url: "/addons/chatgpt/midjourney/appconfig",
		method: "POST",
		desc: "更多应用"
	},
	appnotify: {
		url: "/addons/chatgpt/index/notify",
		method: "POST",
		desc: "通知公告"
	},


}

export default common
