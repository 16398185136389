<template>
  <div id="app">
    <keep-alive>
      <component :is="layout">
        <router-view />
      </component>
    </keep-alive>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
export default {
  computed: {
    ...mapGetters("user", ["token"]),

    layout() {
      return "layout-" + (this.$route.meta.layout || "default").toLowerCase();
    },
  },
  created() {
    this.getWebsiteConfigInfo();
    if (this.token) {
      this.getUserInfo();
    }
  },
  methods: {
    ...mapActions("app", ["getWebsiteConfigInfo"]),
    ...mapActions("user", ["getUserInfo"]),
  },
};
</script>

<style lang="scss"></style>
