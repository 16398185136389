<template>
  <div>
    <!-- Settings Drawer -->
    <a-drawer
      class="settings-drawer"
      placement="right"
      :closable="false"
      :visible="showSettingsDrawer"
      width="360"
      :getContainer="() => wrapper"
      @close="$emit('toggleSettingsDrawer', false)"
    >
      <!-- Settings Drawer Close Button -->
      <a-button
        type="link"
        class="btn-close"
        @click="$emit('toggleSettingsDrawer', false)"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="9"
          height="9"
          viewBox="0 0 9 9"
        >
          <g id="close" transform="translate(0.75 0.75)">
            <path
              id="Path"
              d="M7.5,0,0,7.5"
              fill="none"
              stroke="#000"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-miterlimit="10"
              stroke-width="1.5"
            />
            <path
              id="Path-2"
              data-name="Path"
              d="M0,0,7.5,7.5"
              fill="none"
              stroke="#000"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-miterlimit="10"
              stroke-width="1.5"
            />
          </g>
        </svg>
      </a-button>
      <!-- / Settings Drawer Close Button -->

      <!-- Settings Drawer Content -->
      <div class="drawer-content">
        <h6>设置</h6>
        <p>自定义配置信息</p>
        <hr />
        <div class="download">
          <a-button type="dark" block @click="rightButtonHandle('wxoa')"
            >关注公众号</a-button
          >
          <a-button type="primary" block @click="rightButtonHandle('wxscan')"
            >微信扫码使用</a-button
          >
          <a-button type="secondary" block @click="rightButtonHandle('contact')"
            >联系我们</a-button
          >
          <a-button
            v-if="token"
            type="danger"
            ghost
            block
            @click="logoutDialog = true"
            >退出登录</a-button
          >
        </div>
        <div class="sharing">
          <div class="share-links mt-20">
            <a-button
              v-if="token"
              type="dark"
              size="small"
              @click="copyShareLink"
            >
              <svg
                width="15px"
                height="15px"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path
                  d="M503.691 189.836L327.687 37.851C312.281 24.546 288 35.347 288 56.015v80.053C127.371 137.907 0 170.1 0 322.326c0 61.441 39.581 122.309 83.333 154.132 13.653 9.931 33.111-2.533 28.077-18.631C66.066 312.814 132.917 274.316 288 272.085V360c0 20.7 24.3 31.453 39.687 18.164l176.004-152c11.071-9.562 11.086-26.753 0-36.328z"
                />
              </svg>
              <span>分享</span>
            </a-button>
          </div>
        </div>
      </div>
      <!-- / Settings Drawer Content -->
    </a-drawer>
    <!-- / Settings Drawer -->

    <a-modal
      v-if="
        showModelType === 'wxoa' &&
        config.wxOfficialAccount &&
        config.wxOfficialAccount.qrcode
      "
      v-model="showModal"
      :width="400"
      :footer="null"
      :centered="true"
      :closable="false"
      :maskClosable="true"
      :bodyStyle="{ padding: 0 }"
    >
      <div class="show_modal">
        <div class="wxoa">
          <img :src="config.wxOfficialAccount.qrcode" alt="" />
        </div>
      </div>
    </a-modal>
    <a-modal
      v-if="showModelType === 'wxscan'"
      v-model="showModal"
      :width="400"
      :footer="null"
      :centered="true"
      :closable="false"
      :maskClosable="true"
      :bodyStyle="{ padding: 0 }"
    >
      <div class="show_modal">
        <div class="wxscan">
          <div ref="qrCodeUrl"></div>
        </div>
      </div>
    </a-modal>
    <a-modal
      v-if="
        showModelType === 'contact' &&
        config.contact_us &&
        config.contact_us.content
      "
      v-model="showModal"
      :width="400"
      :footer="null"
      :centered="true"
      :closable="false"
      :maskClosable="true"
      :bodyStyle="{ padding: 0 }"
    >
      <div class="show_modal">
        <div class="contact" v-html="config.contact_us.content"></div>
      </div>
    </a-modal>

    <a-modal
      v-model="logoutDialog"
      title="提示"
      @ok="logoutOK"
      cancel-text="取消"
    >
      <p>确认要退出登录吗？</p>
    </a-modal>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
export default {
  props: {
    // Settings drawer visiblility status.
    showSettingsDrawer: {
      type: Boolean,
      default: false,
    },
    // Main sidebar color.
    sidebarColor: {
      type: String,
      default: "primary",
    },
    // Main sidebar theme : light, white, dark.
    sidebarTheme: {
      type: String,
      default: "light",
    },
    // Header fixed status.
    navbarFixed: {
      type: Boolean,
      default: false,
    },
    // Drawer direction.
    rtl: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // The wrapper element to attach dropdowns to.
      wrapper: document.body,
      // Main sidebar color.
      sidebarColorModel: this.sidebarColor,
      // Main sidebar theme : light, white, dark.
      sidebarThemeModel: this.sidebarTheme,
      // Header fixed status.
      navbarFixedModel: this.navbarFixed,
      showModal: false,
      showModelType: "wxoa", // wxoa wxscan contact
      logoutDialog: false,
    };
  },
  computed: {
    ...mapState("app", ["config", "sign"]),
    ...mapGetters("user", ["token", "userInfo"]),
  },
  mounted: function () {
    // Set the wrapper to the proper element, layout wrapper.
    this.wrapper = document.getElementById("layout-dashboard");
  },
  methods: {
    // 生成二维码
    creatQrCode() {
      // 重复创建二维码会，之前的二维码还在，新创建的会被接在下面
      // 此行代码是为了解决以上问题 或者 刷新二维码的时候生成多个二维码的问题
      if (this.$refs.qrCodeUrl) this.$refs.qrCodeUrl.innerHTML = "";
      new QRCode(this.$refs.qrCodeUrl, {
        text: this.config.h5, // 需要转换为二维码的内容
        width: 340,
        height: 340,
        correctLevel: QRCode.CorrectLevel.H,
      });
    },
    // 按钮
    rightButtonHandle(type) {
      this.showModelType = type;
      this.showModal = true;
      this.$nextTick(() => {
        type == "wxscan" && this.creatQrCode();
      });
    },
    // 退出登录
    logoutOK() {
      this.$store.dispatch("user/logout");
      this.$emit("toggleSettingsDrawer", false);
      this.logoutDialog = false;
      let keys = Object.keys(localStorage);
          keys.forEach((key) => {
            localStorage.removeItem(key);
          });
    },
    // logoutHandle() {
    // 	this.$confirm({
    // 		title: "确认要退出登录吗？",
    // 		content: "",
    // 		okText: "确认",
    // 		cancelText: "取消",
    // 		onOk: () => {
    // 			return new Promise((resolve, reject) => {
    // 				this.$store.dispatch("user/logout")
    // 				this.$emit("toggleSettingsDrawer", false)
    // 				resolve()
    // 			}).catch(() => {})
    // 		}
    // 	})
    // },
    // 复制
    async copyShareLink() {
      const spm = this.userInfo.id + ".1.0.4.3";
      const link =
        window.location.origin +
        window.location.pathname +
        `?${this.sign}#/?share=${spm}`;
      try {
        await navigator.clipboard.writeText(link);
        this.$message.success("已复制到剪切板");
      } catch (err) {
        this.$message.error("复制失败");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .ant-modal-content {
  border-radius: 10px;
  overflow: hidden;
}
.show_modal {
  .wxoa {
    img {
      width: 400px;
      height: 400px;
    }
  }

  .wxscan {
    width: 400px;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .contact {
    ::v-deep img {
      width: 100%;
      height: auto;
    }
  }
}
</style>
